<template>
  <div
    :class="computedClasses"
    aria-atomic
  >
    <slot />
  </div>
</template>

<script setup lang="ts">
const props = withDefaults(defineProps<{
  state?: boolean
}>(), {
  state: true,
})

const computedClasses = computed(() => {
  return [
    'z-form-invalid-feedback',
    props.state === false && 'z-form-invalid-feedback-visible',
  ]
})
</script>
