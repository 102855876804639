<template>
  <div :class="computedClasses">
    <label
      v-if="(label || $slots.label) && !isFieldset"
      :for="labelFor"
      class="d-block"
    >
      <template v-if="label">{{ label }}</template>
      <template v-else><slot name="label" /></template>
    </label>
    <fieldset
      v-else-if="(label || $slots.label) && isFieldset"
      class="z-form-group mb-0"
    >
      <legend
        tabindex="-1"
        :class="['z-no-focus-ring', { 'col-form-label': label || $slots.label }, 'pt-0']"
      >
        <div class="field-label">
          <template v-if="label">
            {{ label }}
          </template>
          <template v-else>
            <slot name="label" />
          </template>
        </div>
      </legend>
    </fieldset>
    <div class="position-relative">
      <slot />
      <ZFormInvalidFeedback
        :state="state"
        :tab-index="invalidFeedback ? '-1' : null"
        :aria-live="feedbackAriaLive"
      >
        <slot
          v-if="$slots['invalid-feedback']"
          name="invalid-feedback"
        />
        <template v-else>
          {{ invalidFeedback }}
        </template>
      </ZFormInvalidFeedback>
      <small
        v-if="description"
        tabindex="-1"
        class="form-text text-muted"
      >{{ description }}</small>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { FormGroupAriaLiveOptions } from '~/types/style-guide'

const props = withDefaults(defineProps<{
  label?: string
  labelFor?: string
  state?: boolean
  invalidFeedback?: string
  description?: string
  feedbackAriaLive?: FormGroupAriaLiveOptions
}>(), {
  label: '',
  labelFor: '',
  state: true,
  invalidFeedback: '',
  description: '',
  feedbackAriaLive: 'assertive',
})

const computedClasses = computed(() => {
  return [
    'z-form-group',
    props.state === false && 'is-invalid',
  ]
})

const isFieldset = computed(() => {
  return !props.labelFor
})
</script>
